import React from "react"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import topBannerImg from "../images/top-banner-icon.png"
import topBannerBackground from "../images/top-banner.jpg"
// import productDevlopmentImg from "../images//product-development-img1.png"
import specialiteWebsite from "../images/specialite_maison_website.png"
import specialiteMobile from "../images/specialite_maison_mobile.png"
import specialiteDesktop from "../images/specialite_maison_desktop.png"
import specialiteBackend from "../images/specialite_maison_backend.png"
// import elctronJs from "../images/electron-js.png"
// import nodeJs from "../images/node-js.png"
// import reactJs from "../images/react-js.png"
// import javascript from "../images/javascript.png"
// import mongoDb from "../images/mongo-db.png"
import profilePic from "../images/profile-pic.jpg"
// import portfolioImg1 from "../images/portfolio-img-1.jpg"
// import portfolioImg2 from "../images/portfolio-img-2.jpg"
import "../styles/global.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated, useSprings } from "react-spring"
import SplitText from "react-pose-text"
// import Tilt from "react-tilt"
import Header from "../components/header"
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons"
const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
]
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function Dash() {
  let parallax
  // Little helpers ...
  const url = (name, wrap = false) =>
    `${
      wrap ? "url(" : ""
    }https://awv3node-homepage.surge.sh/build/assets/${name}.svg${
      wrap ? ")" : ""
    }`
  const Pink = ({ children }) => (
    <span style={{ color: "#FF6AC1" }}>{children}</span>
  )
  const Yellow = ({ children }) => (
    <span style={{ color: "#EFF59B" }}>{children}</span>
  )
  const Lightblue = ({ children }) => (
    <span style={{ color: "#9AEDFE" }}>{children}</span>
  )
  const Green = ({ children }) => (
    <span style={{ color: "#57EE89" }}>{children}</span>
  )
  const Blue = ({ children }) => (
    <span style={{ color: "#57C7FF" }}>{children}</span>
  )
  const Gray = ({ children }) => (
    <span style={{ color: "#909090" }}>{children}</span>
  )

  const charPoses = {
    exit: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      delay: ({ charIndex }) => charIndex * 30,
    },
  }
  const [springs, sets, stop] = useSprings(2, index => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  const { o, xyz } = useSpring({
    from: { o: 0, xyz: [0, 0, 0], color: "red" },
    o: 1,
    xyz: [10, 20, 5],
  })
  const data = useStaticQuery(graphql`
    query MysQuery {
      topBannerImage: file(relativePath: { eq: "top-banner-icon.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productDevelopmentImage: file(
        relativePath: { eq: "product-development-img1.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteWebsiteImage: file(
        relativePath: { eq: "specialite_maison_website.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteMobileImage: file(
        relativePath: { eq: "specialite_maison_mobile.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteDesktopImage: file(
        relativePath: { eq: "specialite_maison_desktop.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteBackendImage: file(
        relativePath: { eq: "specialite_maison_backend.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      profilePicImage: file(relativePath: { eq: "profile-pic.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }

      portfolioImg1: file(relativePath: { eq: "portfolio-img-1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      portfolioImg2: file(relativePath: { eq: "portfolio-img-1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header />
      <Parallax ref={ref => (parallax = ref)} pages={4}>
        <ParallaxLayer
          offset={1}
          speed={1}
          style={{ backgroundColor: "#30275b" }}
        />
        <ParallaxLayer
          offset={2}
          speed={1}
          style={{ backgroundColor: "#87BCDE" }}
        />

        <ParallaxLayer
          offset={3}
          speed={1}
          style={{ backgroundColor: "#87BCDE" }}
        />

        <ParallaxLayer
          offset={0}
          speed={0}
          factor={1}
          style={{
            backgroundImage: `url(${topBannerBackground})`,
            backgroundSize: "cover",
          }}
        />

        {/* <ParallaxLayer
          offset={1.3}
          speed={-0.3}
          style={{ pointerEvents: "none" }}
        >
          <img
            src={url("satellite4")}
            style={{ width: "15%", marginLeft: "70%" }}
          />
        </ParallaxLayer> */}

        {/* <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "55%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "10%", marginLeft: "15%" }}
          />
        </ParallaxLayer> */}
        {/* 
        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "70%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "40%" }}
          />
        </ParallaxLayer> */}

        <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "10%", marginLeft: "10%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "75%" }}
          />
        </ParallaxLayer>

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "60%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "25%", marginLeft: "30%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "10%", marginLeft: "80%" }}
          />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          <img
            src={url("cloud")}
            style={{ display: "block", width: "20%", marginLeft: "5%" }}
          />
          <img
            src={url("cloud")}
            style={{ display: "block", width: "15%", marginLeft: "75%" }}
          />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.5}
          speed={-0.4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <img src={url("earth")} style={{ width: "60%" }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            backgroundSize: "80%",
            backgroundPosition: "center",
            backgroundImage: url("clients", true),
          }}
        />

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallax.scrollTo(1)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <section className="wrapper top_banner">
            <div className="container">
              <div className="wrapper top_banner_slider">
                <div className="wrapper colwrap">
                  <div className="textwrap">
                    <h1 className="h1">
                      <SplitText
                        initialPose="exit"
                        pose="enter"
                        charPoses={charPoses}
                      >
                        We're JavaScript brainiacs, ready to build your next
                        product or join your team remotely.
                      </SplitText>
                    </h1>
                  </div>
                  <div className="imgwrap">
                    <Img fluid={data.topBannerImage.childImageSharp.fluid} />
                    {/* <img src={topBannerImg} alt="" /> */}
                  </div>
                </div>
              </div>
              <a href="#development">
                <abbr className="down_arrow"></abbr>
              </a>
            </div>
          </section>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0.1}
          onClick={() => parallax.scrollTo(2)}
          //   style={{
          //     display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //   }}
        >
          <section className="wrapper banner_copy_text" id="development">
            <div className="container">
              <h4 className="wrapper h4">
                We combine the advice of business and technology of last
                generation, to create solutions to the extent of their world.
                And that makes us unique.
              </h4>
            </div>
          </section>

          <section className="wrapper product_development">
            <div className="container">
              <div className="wrapper two_col_main">
                <span className="wrapper services_heading_text">
                  Product Development
                </span>
                <div className="wrapper two_col">
                  <div className="imgwrap">
                    <animated.div
                      className="card"
                      onMouseMove={({ clientX: x, clientY: y }) =>
                        set({ xys: calc(x, y) })
                      }
                      onMouseLeave={() => set({ xys: [0, 0, 1] })}
                      style={{ transform: props.xys.interpolate(trans) }}
                    >
                      <Img
                        fluid={
                          data.productDevelopmentImage.childImageSharp.fluid
                        }
                      />
                      {/* <img src={productDevlopmentImg} alt="" /> */}
                    </animated.div>
                  </div>
                  <div className="textwrap">
                    <span>Product Development</span>
                    <h3 className="h3">
                      Turn your ideas and experience into a digital product with
                      our remote team.
                    </h3>
                    <p className="wrapper">
                      Looking for a team to build a web, mobile or desktop
                      application based on your idea? Hire one of our
                      experienced teams of Developers, Product Owners, UI/UX
                      Designers and QA Engineers. Additionally, our C-level
                      management will consult with you on your product to help
                      you make it successful. Your success is our sucess.
                    </p>
                    <a href="javascript:void(0)">
                      <span className="btn2">Read more</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => parallax.scrollTo(0)}
        >
          <section className="wrapper specialite_maison">
            <div className="container">
              <h2 className="wrapper h2">JS is our Spécialité de la maison</h2>
            </div>
            <div className="container">
              <a href="javascript:void(0)">
                <span className="btn2">Know more</span>
              </a>

              <ul className="wrapper services_list">
                {/* {items.map(i => (
              <animated.div
                key={i}
                className="script-bf-box"
                style={{ transform: radians.interpolate(interp(i)) }}
              >
                <li>
                  <div className="maisonimghide">
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <h5 className="h5">Web Application Development</h5>
                  <p className="maisonpara">
                    We build highly scalable platforms and well-tested products
                    that are ready to handle million of users on a daily basis.
                  </p>
                  <span className="vertical_text">website</span>
                </li>
              </animated.div>
            ))} */}
                <li>
                  <div className="maisonimghide">
                    {/* <Img
                  fluid={data.specialiteWebsiteImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    {/* <Img
                  fluid={data.specialiteWebsiteImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <h5 className="h5">Web Application Development</h5>
                  <p className="maisonpara">
                    We build highly scalable platforms and well-tested products
                    that are ready to handle million of users on a daily basis.
                  </p>
                  <span className="vertical_text">website</span>
                </li>
                <li>
                  <div className="maisonimghide">
                    {/* <Img fluid={data.specialiteMobileImage.childImageSharp.fluid} /> */}
                    <img src={specialiteMobile} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    {/* <Img fluid={data.specialiteMobileImage.childImageSharp.fluid} /> */}
                    <img src={specialiteMobile} alt="" />
                  </div>
                  <h5 className="h5">Mobile Application Development</h5>
                  <p className="maisonpara">
                    iOS and Android apps built with React Native based on robust
                    Node.js backend will satisfy every end-user.
                  </p>
                  <span className="vertical_text">mobile</span>
                </li>
                <li>
                  <div className="maisonimghide">
                    {/* <Img
                  fluid={data.specialiteDesktopImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteDesktop} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    {/* <Img
                  fluid={data.specialiteDesktopImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteDesktop} alt="" />
                  </div>
                  <h5 className="h5">Desktop Application Development</h5>
                  <p className="maisonpara">
                    Thanks to Electron JS, we build desktop applications for
                    internal and external usage. Be like Slack who uses Electron
                    JS for their desktop app.
                  </p>
                  <span className="vertical_text">desktop</span>
                </li>
                <li>
                  <div className="maisonimghide">
                    {/* <Img
                  fluid={data.specialiteBackendImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteBackend} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    {/* <Img
                  fluid={data.specialiteBackendImage.childImageSharp.fluid}
                /> */}
                    <img src={specialiteBackend} alt="" />
                  </div>
                  <h5 className="h5">Backend Development</h5>
                  <p className="maisonpara">
                    Node.js together with MongoDB/ArangoDB/ RabbitMQ and tools
                    adjusted to your needs are the heart of our backends.
                  </p>
                  <span className="vertical_text">backend</span>
                </li>
              </ul>
              {/* <!-- <div className="wrapper amazing_technologies">
            <div className="tech_text">
                <h3 className="wrapper h3">
                    Our Services are driven by amazing Technologies
                </h3>
                <p className="wrapper">
                    Additionally, we build software using: ES6, Redux, Redis, Express, Typescript, Microservices, ArangoDB, RabbitMQ, Selenium,
                    Chai.js, Cucumber, Ansible, Consul, Gitlab, Gitlab CI, Flow Type.
                </p>
            </div>
            <div className="tech_logos">
                <ul>
                    <li>
                        <img src="images/electron-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/node-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/react-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/javascript.png" alt="" />
                    </li>
                    <li>
                        <img src="images/mongo-db.png" alt="" />
                    </li>
                </ul>
            </div>
        </div> --> */}
            </div>
          </section>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={0.1}
          onClick={() => parallax.scrollTo(1)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <section className="wrapper our_client_say">
            <h2 className="wrapper h2">Our clients say</h2>
            <div className="wrapper our_client_say_slider">
              <div className="colwrap">
                <span className="circle_img">
                  <abbr>
                    {/* <Img fixed={data.profilePicImage.childImageSharp.fixed} /> */}
                    <img src={profilePic} alt="" />
                  </abbr>
                </span>
                <p className="wrapper">
                  It has been a pleasure working with you and seeing this
                  project through from start to finish. Thank you guys for your
                  high level of expertise, flexibility and creativity in
                  approach and diligence in handling in addition to always being
                  available, present and kind.
                </p>
                <strong className="wrapper">
                  <abbr>Nikunj Mavani</abbr>
                </strong>
                <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
              </div>
              <div className="colwrap slick-active">
                <span className="circle_img">
                  <abbr>
                    {/* <Img fixed={data.profilePicImage.childImageSharp.fixed} /> */}
                    <img src={profilePic} alt="" />
                  </abbr>
                </span>
                <p className="wrapper">
                  It has been a pleasure working with you and seeing this
                  project through from start to finish. Thank you guys for your
                  high level of expertise, flexibility and creativity in
                  approach and diligence in handling in addition to always being
                  available, present and kind.
                </p>
                <strong className="wrapper">
                  <abbr>Nikunj Mavani</abbr>
                </strong>
                <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
              </div>
              <div className="colwrap">
                <span className="circle_img">
                  <abbr>
                    {/* <Img fixed={data.profilePicImage.childImageSharp.fixed} /> */}
                    <img src={profilePic} alt="" />
                  </abbr>
                </span>
                <p className="wrapper">
                  It has been a pleasure working with you and seeing this
                  project through from start to finish. Thank you guys for your
                  high level of expertise, flexibility and creativity in
                  approach and diligence in handling in addition to always being
                  available, present and kind.
                </p>
                <strong className="wrapper">
                  <abbr>Nikunj Mavani</abbr>
                </strong>
                <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
              </div>
            </div>
            <ul className="slick-dots">
              <li className="slick-active">01</li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </section>
        </ParallaxLayer>
      </Parallax>
    </React.Fragment>
  )
}

export default Dash
